import React, { useEffect, useState } from "react";
import styles from "./LinearEquationOne.module.css"
import { useLineOneEquation, useLanguage } from "../../hooks"
const LinearEquationOne: React.FC = () => {
    const { deltal, result, setA, setB, solveQuadraticEquation, data,a,b, } = useLineOneEquation()

    const { memoizedGetMessage } = useLanguage()

    
    return (
        <div >
            <h2>{memoizedGetMessage(`PT bậc nhất 1 ẩn`)}</h2>
            <div>
                <div className={styles.screenInput}>
                    <div>
                        <label>
                            a:{" "}
                            <input
                                pattern="[0-9]*"
                                className={styles.input}
                                type="number"
                                required
                                onChange={(e) => setA(Number(e.target.value))}
                            />
                        </label>
                    </div>
                    <div>
                        <label>
                            b:{" "}
                            <input
                                pattern="[0-9]*"
                                className={styles.input}
                                type="number"
                                required
                                onChange={(e) => setB(Number(e.target.value))}
                            />
                        </label>
                    </div>
                 
                </div>
                <button className={styles.button} onClick={solveQuadraticEquation}>{memoizedGetMessage(`Slove`)}</button>
                <div className={styles.screenInput}>
                {a? a:"a"}x + {b ?b:"b"}  = 0 ( a ≠ 0)
                </div>
                <div className={styles.screenInput}>
                    {data ? <div className={styles.typewriter}>
                        <p style={{textAlign:'center',fontWeight:'bold'}}>Bài giải:</p>
                        <p
                            className={styles.descriptionContent}
                            dangerouslySetInnerHTML={{
                                __html: `${data ? data : `${memoizedGetMessage(``)}`}`,
                            }}
                        />
                    </div>:null}
                </div>
                <div className={styles.result}>

                    <strong>{memoizedGetMessage(`Result`)}:</strong> {result ? result : `${memoizedGetMessage(`The solution will appear here`)}`}
                </div>
            </div>
        </div>
    );
};

export default LinearEquationOne;
