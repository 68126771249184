import React, { useEffect, useState } from "react";
import styles from "./ThirdraticEquationSolver.module.css"
import { useQuadraticEquation, useLanguage,useThirdraticEquation } from "../../hooks"
const ThirdraticEquationSolver: React.FC = () => {
    const { solutions, setA, setB, setC, solveCubicEquation,a,b,c,setD,d,data } = useThirdraticEquation()

    const { memoizedGetMessage } = useLanguage()
    
    
    return (
        <div >
            <h2>{memoizedGetMessage(`Solving Cubic equations`)}</h2>
            <div>
                <div className={styles.screenInput}>
                    <div>
                        <label>
                            a:{" "}
                            <input
                                pattern="[0-9]*"
                                className={styles.input}
                                type="number"
                                required
                                onChange={(e) => setA(Number(e.target.value))}
                            />
                        </label>
                    </div>
                    <div>
                        <label>
                            b:{" "}
                            <input
                                pattern="[0-9]*"
                                className={styles.input}
                                type="number"
                                required
                                onChange={(e) => setB(Number(e.target.value))}
                            />
                        </label>
                    </div>
                    <div>
                        <label>
                            c:{" "}
                            <input
                                type="number"
                                required
                                pattern="[0-9]*"
                                className={styles.input}
                                onChange={(e) => setC(Number(e.target.value))}
                            />
                        </label>
                    </div>
                    <div>
                        <label>
                            d:{" "}
                            <input
                                type="number"
                                required
                                pattern="[0-9]*"
                                className={styles.input}
                                onChange={(e) => setD(Number(e.target.value))}
                            />
                        </label>
                    </div>
                </div>
                <button className={styles.button} onClick={solveCubicEquation}>{memoizedGetMessage(`Slove`)}</button>
                <div className={styles.screenInput}>
                {a? a:"a"}x³ + {b ?b:"b"}x² + {c ?c :"c"}x +  {d ?d :"d"} = 0 ( a ≠ 0)
                </div>
                <div className={styles.screenInput}>
                    {data ? <div className={styles.typewriter}>
                        <p style={{textAlign:'center',fontWeight:'bold'}}>Bài giải:</p>
                        <p
                            className={styles.descriptionContent}
                            dangerouslySetInnerHTML={{
                                __html: `${data ? data : `${memoizedGetMessage(``)}`}`,
                            }}
                        />
                    </div>:null}
                </div>
                <div className={styles.result}>

                    <strong>{memoizedGetMessage(`Result`)}:</strong> {solutions ? <div>{solutions?.map((item:any,index:number)=>(
                        <div>{`x${index+1}: `+ item}</div>
                    ))}</div> : `${memoizedGetMessage(`The solution will appear here`)}`}
                </div>
            </div>
        </div>
    );
};

export default ThirdraticEquationSolver;
