
import { useCallback, useState } from "react"
import useLanguage from "./useLanguage";

const useQuadraticEquation = () => {
    const [a, setA] = useState<number>();
    const [b, setB] = useState<number>();
    const [c, setC] = useState<number>();
    const [data, setData] = useState<any>()
    const [deltal, setDetal] = useState<number>();
    const [result, setResult] = useState<string>("");
    const { memoizedGetMessage } = useLanguage()
    const solveQuadraticEquation = useCallback(async () => {
        if (typeof a === "undefined" || typeof b === "undefined" || typeof c === "undefined") {
            setData(`Vui lòng cung cấp đầy đủ các hệ số a,b,c`)
            return;
        }

        if (a === 0) {
            setResult(memoizedGetMessage(`The equation is not a quadratic equation`));
            setData(`
            <div  class="type1">
            Ta có PT bậc 2 1 ẩn: ${a}x² + ${b}x+ ${c} = 0 <br/>
            Xét điều kiện thấy  : a =0 , do đó đây không phải là một PT bậc 2
            </div>
            `)
            return;
        }

        const delta = b * b - 4 * a * c;
        setDetal(delta)


        switch (true) {
            case delta > 0:
                setData(`
            <div class="type1">
            <p > Ta có PT bậc 2 1 ẩn: ${a}x² + ${b}x+ ${c} = 0 <br/></p>
            <p > Công thức nghiệm delta : b²- 4 * a * c = ${delta}<br/></p>
            <p >  ∆ > 0: PT có 2 nghiệm phân biệt: <br/></p>
            <p > x1= -b+√∆ / 2*a <=> -${b}+√${delta}/2 * ${a} <br/></p>
            <p > x2= -b-√∆ / 2*a <=> -${b}+√${delta}/2 * ${a} <br/></p>
            </div>

         `)
                const x1 = (-b + Math.sqrt(delta)) / (2 * a);
                const x2 = (-b - Math.sqrt(delta)) / (2 * a);
                setResult(`${memoizedGetMessage(`The equation has two distinct solution`)}: x1 = ${x1}, x2 = ${x2}`);
                break;
            case delta === 0:
                setData(`
                <div  class="type1">
                Ta có PT bậc 2 1 ẩn: ${a}x² + ${b}x+ ${c} = 0 <br/>
                Công thức nghiệm delta : b²- 4 * a * c = ${delta}<br/>
                ∆ = 0:<br/>
                PT có 1 nghiệm : <br/>
                x= -b / (2*a) <=> -${b}/(2 * ${a}) <br/>
                </div>
             
          `)
                const x = -b / (2 * a);
                setResult(`${memoizedGetMessage(`The equation has a double solution`)}: x = ${x}`);
                break;
            default:
                setData(`
                <div class="type1">
                Ta có PT bậc 2 1 ẩn: ${a}x² + ${b}x+ ${c} = 0 <br/>
                Công thức nghiệm delta : b²- 4 * a * c = ${delta}<br/>
                ∆ < 0:<br/>
                PT  vô nghiệm  <br/>
                </div>
             
             
          `)
                setResult(memoizedGetMessage(`The equation has no solution`));
        }
    }, [a, b, c, memoizedGetMessage])
    return {
        a, deltal, b, c, solveQuadraticEquation, setA, setB, setC, result, data
    }
}
export default useQuadraticEquation;
