import React, { useState } from 'react';
import styles from "./LinearEquationSolver.module.css"
import { useLanguage, useLineEquation } from 'src/hooks';

function LinearEquationSolver() {

    const [solution, setSolution] = useState('');
    const { handleInputChange, result, solveLinearEquations, a, b, c, a2, b2, c2, data } = useLineEquation()



    const { memoizedGetMessage } = useLanguage()
    return (
        <div>
            <h1>Giải hệ phương trình tuyến tính</h1>

            <div className={styles.screenInput}>
                <div>
                    <label htmlFor="a">a: </label>
                    <input type="number"
                        pattern="[0-9]*"
                        className={styles.input}
                        id="a" name="a" onChange={handleInputChange} />
                </div>
                <div>
                    <label htmlFor="b">b: </label>
                    <input type="number"
                        pattern="[0-9]*"
                        className={styles.input}
                        id="b" name="b" onChange={handleInputChange} />
                </div>
                <div>
                    <label htmlFor="c">c: </label>
                    <input type="number"
                        pattern="[0-9]*"
                        id="c" name="c" className={styles.input}
                        onChange={handleInputChange} />
                </div>
            </div>
            <div className={styles.screenInput} style={{ marginTop: '5px' }}>
                <div>
                    <label htmlFor="a1">a': </label>
                    <input type="number" id="a2" name="a2" className={styles.input}
                        onChange={handleInputChange} />
                </div>
                <div>
                    <label htmlFor="b1">b': </label>
                    <input type="number" id="b2" name="b2" className={styles.input}
                        onChange={handleInputChange} />
                </div>
                <div>
                    <label htmlFor="c1">c': </label>
                    <input type="number" id="c2" name="c2" className={styles.input}
                        onChange={handleInputChange} />
                </div>
            </div>
            <button className={styles.button} onClick={solveLinearEquations}>{memoizedGetMessage(`Slove`)}</button>
            <div className={styles.screenInput}>
                <div>
                    <p>{a ? a : "a"}x + {b ? b : "b"}y ={c ? c : "c"} (1)</p>
                    <p>{a2 ? a2 : "a'"}x + {b2 ? b2 : "b'"}y ={c2 ? c2 : "c'"} (2)</p>
                </div>

            </div>
            <div className={styles.screenInput}>
                <div className={styles.typewriter}>
                {data ? <div className={styles.typewriter}>
                        <p style={{textAlign:'center',fontWeight:'bold'}}>Bài giải:</p>
                        <p
                            className={styles.descriptionContent}
                            dangerouslySetInnerHTML={{
                                __html: `${data ? data : `${memoizedGetMessage(``)}`}`,
                            }}
                        />
                    </div>:null}
                </div>
            </div>
            <div>
                <h2>Kết quả:</h2>
                <p
                    className={styles.descriptionContent}
                    dangerouslySetInnerHTML={{
                        __html: `${result ? result : `${memoizedGetMessage(`The solution will appear here`)}`}`,
                    }}
                />
                {/* <p>{result ? result : `${memoizedGetMessage(`The solution will appear here`)}`}</p> */}
            </div>
        </div>
    );
}

export default LinearEquationSolver;
