import { useCallback, useState } from "react";
import { evaluate, sqrt } from 'mathjs';
const useCubicEquation = () => {
    const [a, setA] = useState<number>();
    const [b, setB] = useState<number>();
    const [c, setC] = useState<number>();
    const [d, setD] = useState<number>();
    const [data, setData] = useState("");

    const [solutions, setSolutions] = useState([]);
    
  const epsilon = 1e-6; // Độ sai số cho phép
  const maxIterations = 100; // Số lần lặp tối đa

  // gpt bậc 4
function solveQuartic(a: number, b: number, c: number, d: number, e: number): number[] {
  let f = ((-3 * Math.pow(b, 2)) / (8 * Math.pow(a, 2))) + ((c) / (a));
  let g = ((Math.pow(b, 3)) / (8 * Math.pow(a, 3))) - ((b * c) / (2 * Math.pow(a, 2))) + ((d) / (a));
  let h = ((-3 * Math.pow(b, 4)) / (256 * Math.pow(a, 4))) + ((Math.pow(b, 2) * c) / (16 * Math.pow(a, 3))) - ((b * d) / (4 * Math.pow(a, 2))) + ((e) / (a));
  
  let p = (-Math.pow(f, 2)) / (12 * h);
  let q = (-f * p) / (3 * h);
  let r = (Math.pow(g, 2)) / (8 * Math.pow(h, 2)) + ((p) / (2 * h)) - ((f) / (3 * h));
  
  let y: number;
  
  if(Math.pow(q, 3) + Math.pow(r, 2) > 0) {
    y = Math.cbrt(-r + Math.sqrt(Math.pow(q, 3) + Math.pow(r, 2)));
    y += Math.cbrt(-r - Math.sqrt(Math.pow(q, 3) + Math.pow(r, 2)));
    y -= f / (3 * h);
    
    return [y];
    
  } else if(Math.pow(q, 3) + Math.pow(r, 2) == 0) {
    let z = (-5 * q) / (6 * r);
    y = z - f / (3 * h);
    
    return [y];
    
  } else {
    let s = Math.sqrt(-(Math.pow(q, 3) + Math.pow(r, 2)));
    let u = Math.cbrt(s - r);
    let v = -Math.cbrt(s + r);
    y = u + v - f / (3 * h);
    
    return [y];
    
    // Uncomment the following lines to include complex roots
    //let x1 = u + v - f / (3 * h);
    //let x2_re = -(u + v) / 2 - f / (3 * h);
    //let x2_im = (u - v) * Math.sqrt(3) / 2;
    //let x3_re = -(u + v) / 2 - f / (3 * h);
    //let x3_im = -(u - v) * Math.sqrt(3) / 2;
    //return [x1,x2_re+x2_im+'i',x3_re+x3_im+'i'];
    
   }
}

// gpt bậc 3


    const solveCubicEquation = useCallback(() => {
        if (typeof a === "undefined" || typeof b === "undefined" || typeof c === "undefined"|| typeof d === "undefined"  ) {
            setData(`Vui lòng cung cấp đầy đủ các hệ số a,b,c`)
            return;
        }
      const discriminant = 18 * a * b * c * d - 4 * b ** 3 * d + b ** 2 * c ** 2 - 4 * a * c ** 3 - 27 * a ** 2 * d ** 2;
  
      if (discriminant === 0) {
        const solution = -(b / (3 * a));
        setSolutions([solution]);
      } else if (discriminant > 0) {
        const C = Math.cbrt((discriminant + Math.sqrt(discriminant)) / 2);
        const D = Math.cbrt((discriminant - Math.sqrt(discriminant)) / 2);
        const root1 = (-b / (3 * a)) + (C + D);
        setSolutions([root1]);
      } else {
        const t = (2 * Math.sqrt(-discriminant) * Math.cos(Math.acos((3 * b - 2 * a ** 2 * b) / (2 * a ** 3 * Math.sqrt(-discriminant))) / 3));
        const root1 = (t - b) / (3 * a);
        const root2 = (-t / 2 - b) / (3 * a) + (t * Math.sqrt(3)) / (2 * a);
        const root3 = (-t / 2 - b) / (3 * a) - (t * Math.sqrt(3)) / (2 * a);
        setSolutions([root1.toFixed(2), root2.toFixed(2), root3.toFixed(2)]);

      }
    }, [a, b, c, d]);
  
    return { setA, setB, setC, setD, a, b, c, d, solveCubicEquation, solutions,data };
  };
  
  export default useCubicEquation;
