import { useCallback, useMemo, useState } from "react"


interface Fraction {
  numerator: number;
  denominator: number;
}
function roundToSixDecimalPlaces(n: number) {
  // Sử dụng phương thức toFixed(6) để làm tròn số thập phân
  return Number(n.toFixed(3));
}





const useLineEquation = () => {
  const [a, setA] = useState<number>();
  const [b, setB] = useState<number>();
  const [c, setC] = useState<number>();
  const [a2, setA2] = useState<number>();
  const [b2, setB2] = useState<number>();
  const [c2, setC2] = useState<number>();
  const [result, setResult] = useState<string | undefined>();
  const [data, setData] = useState<string | undefined>();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'a') setA(parseInt(value));
    else if (name === 'b') setB(parseInt(value));
    else if (name === 'c') setC(parseInt(value));
    else if (name === 'a2') setA2(parseInt(value));
    else if (name === 'b2') setB2(parseInt(value));
    else if (name === 'c2') setC2(parseInt(value));

  };
  function isInfinity(number) {
    return number === Infinity || number === -Infinity;
  }
  const solveLinearEquations = useCallback(async () => {
    if (typeof a === "undefined" || typeof b === "undefined" || typeof c === "undefined" || typeof a2 === "undefined" || typeof b2 === "undefined" || typeof c2 === "undefined") {
      setData(`Vui lòng cung cấp đầy đủ các hệ số `)
      return;
    }


    let d = a * b2 - a2 * b;

    let dx = c * b2 - c2 * b;
    let dy = a * c2 - a2 * c;
    let x0 = dx / d;
    let y0 = dy / d;
    setData(`
      <div class="type1">
      Từ hệ pt trên ta có :<br/>
      <div>
      <p>  d = ${a} * ${b2} - ${a2} * ${b}<br/></p>
       <p>dx = ${c} * ${b2} - ${c2} * ${b}<br/></p>
       <p>dy = ${a} * ${c2} - ${a2} * ${c}<br/></p>
      </div>
     
      <=> <div>
      <p>d = ${a * b2} - ${a2 * b}<br/></p>
       <p>dx = ${c * b2} - ${c2 * b}<br/></p>
      <p> dy = ${a * c2} - ${a2 * c}<br/></p>

      </div>
      <div>
      <=> <p>x = ${dx}/ ${d} = ${isInfinity(dx / d) ? "Vô lý" : `${dx / d}`}<br/></p>
       <p>y = ${dy}/ ${d} = ${isInfinity(dy / d) ? "Vô lý" : `${dy / d}`}<br/></p>
      </div>
      </div>
    
      `)
    if (d === 0) {
      if (dx === 0 && dy === 0) {
        setResult('Hệ phương trình có vô số nghiệm');
      } else {
        setResult('Hệ phương trình vô nghiệm');
      }
    } else {
      const phanSo1 = roundToSixDecimalPlaces(x0);
      const phanSo2 = roundToSixDecimalPlaces(y0);
      setResult(`X = ${phanSo1}, Y = ${phanSo2}`);
    }
  }, [a, a2, b, b2, c, c2])


  return { solveLinearEquations, handleInputChange, result, a, b, c, a2, b2, c2, data };
};

export default useLineEquation
