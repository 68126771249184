
import { useCallback, useState } from "react"
import useLanguage from "./useLanguage";

const useLineOneEquation = () => {
    const [a, setA] = useState<number>();
    const [b, setB] = useState<number>();
   
    const [data, setData] = useState<any>()
    const [deltal, setDetal] = useState<number>();
    const [result, setResult] = useState<string>("");
    const { memoizedGetMessage } = useLanguage()
    const solveQuadraticEquation = useCallback(async () => {
        if (typeof a === "undefined" || typeof b === "undefined" ) {
            setData(`Vui lòng cung cấp đầy đủ các hệ số a,b`)
            return;
        }
        
        
        if (a === 0) {
            if (b === 0) {
                setData(`
                <div  class="type1">
                Ta có PT bậc nhất 1 ẩn: ${a}x + ${b} = 0 <br/>
                <p>Xét thấy  ta có a = 0 và b =0 , do vậy Phương trình này có vô số nghiệm </p>
                </div>
                `)
                setResult(`${memoizedGetMessage(`The equation has infinitely many solutions`)}`);

            } else {
                setData(`
                <div  class="type1">
                Ta có PT bậc nhất 1 ẩn: ${a}x + ${b} = 0 <br/>
                <p>Xét thấy  ta có a = 0 và b ≠ 0 , do vậy Phương trình này vô  nghiệm </p>
                </div>
                `)
                setResult(`${memoizedGetMessage(`The equation has no solution`)}`);

            }
          } else {
            setData(`
            <div  class="type1">
            Ta có PT bậc nhất 1 ẩn: ${a}x + ${b} = 0 <br/>
            x = -b/a => -${b}/ ${a} <=>${-b / a}
            </div>
            `)
            const x = -b / a;
            setResult(`${memoizedGetMessage(`PT Có 1 nghiệm`)}: x = ${x}`);
            return "Nghiệm của phương trình là x = " + x;
          }

       
    }, [a, b, memoizedGetMessage])
    return {
        a, deltal, b,  solveQuadraticEquation, setA, setB,  result, data
    }
}
export default useLineOneEquation;
