import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { QuadraticEquationSolver, LinearEquationSolver, LinearEquationOne,ThirdraticEquationSolver } from "./components"
import { useLanguage } from './hooks';
function App() {
  const [isQuation, setQuadratic] = useState("quard");
  const { memoizedGetMessage, isVietNam, onChangeLang, } = useLanguage();
  const onChange = (e: any) => {
    setQuadratic(e.target.value);

  }

  return (
    <div className="App">
      {isQuation === "line" ? <LinearEquationSolver /> : isQuation === "quard" ? <QuadraticEquationSolver /> : isQuation === "quard1" ? <LinearEquationOne /> :
       isQuation === "quard3" ?<ThirdraticEquationSolver/>:
       <div style={{ marginTop: '20px' }}>
        <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Sắp ra mắt</p>
      </div>}
      <div onChange={onChange} style={{ display: 'flex', margin: 'auto', justifyContent: 'center', flexWrap: 'wrap', }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
        }}>

          <div><input type="radio" value="quard1" name="quard1" checked={isQuation === "quard1"} /> {memoizedGetMessage("Phương trình bậc nhất 1 ẩn")}</div>
          <div> <input type="radio" value="quard" name="quard" checked={isQuation === "quard"} /> {memoizedGetMessage("Solving quadratic equations")}</div>
          <input type="radio" disabled  value="quard3" name="quard3" checked={isQuation === "quard3"} /> {memoizedGetMessage("Phương trình bậc 3 1 ẩn")}
          <input type="radio" value="line" name="equa" checked={isQuation === "line"} /> {memoizedGetMessage("Line quadratic equations")}

        </div>


      </div>
      <div onChange={onChange} style={{ display: 'flex', margin: 'auto', justifyContent: 'center', flexWrap: 'wrap', }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
        }}>
          <div><input type="radio" disabled value="quard4" name="equa4" checked={isQuation === "equa4"} /> {memoizedGetMessage("Phương trình bậc 4 1 ẩn")}</div>

          <div><input type="radio" disabled value="quard2" name="equa2" checked={isQuation === "equa2"} /> {memoizedGetMessage("Phương trình bậc 2 2 ẩn")}</div>
          <div> <input type="radio" disabled value="quardsin" name="quardsin" checked={isQuation === "quardsin"} /> {memoizedGetMessage("Phương trình lượng giác")}</div>
          <input type="radio" disabled value="daoham" name="daoham" checked={isQuation === "daoham"} /> {memoizedGetMessage("Đạo Hàm")}
          <input type="radio" disabled value="tichphan" name="tichphan" checked={isQuation === "tichphan"} /> {memoizedGetMessage("Tích phân")}

        </div>


      </div>

    </div>
  );
}

export default App;
