/*
 Copyright 2023 Phuong My Chi Entertainment Co.,Ltd

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

      https://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */



 import { useState } from "react";

 const useLanguage  = () => {
   const [isVietNam, setVietNam] = useState("vie");
     const englishMessages = {
         "English":"English",
         "Vietnamese":"Vietnamese",
         "Solving quadratic equations":"Solving quadratic equations",
         "The equation is not a quadratic equation":"The equation is not a quadratic equation",
         "The equation has two distinct solution":"The equation has two distinct solutions",
         "The equation has no solution":"The equation has no solution",
         "Result":"Result",
         "Slove":"Slove",
         "Solving Cubic equations":"Solving Cubic equations",
         "Line quadratic equations":"Line quadratic equations",
         "The equation has infinitely many solutions":"The equation has infinitely many solutions",
         "Based on the solution formula: delta = b * b - 4 * a * c, we can calculate Delta":"Based on the solution formula: delta = b * b - 4 * a * c, we can calculate Delta",
         "The solution will appear here":"The solution will appear here"
        
       };
     
       const vietnameseMessages = {
         "English":"Tiếng anh",
        "Vietnamese":"Tiếng việt",
        "Solving quadratic equations":"Giải phương trình bậc hai 1 ẩn",
        "The equation is not a quadratic equation":"Đây không phải là phương trình bậc 2",
        "The equation has two distinct solution":"Phương trình có 2 nghiệm phân biệt",
        "The equation has no solution":"Phương trình không có nghiệm thực",
        "Result":"Kết quả",
        "Solving Cubic equations":"Phương trình bậc 3 1 ẩn",

        "The equation has infinitely many solutions":"Phương trình có vô số nghiệm",
        "The equation has a double solution":"Phương trình có 1 nghiệm kép",
        "The solution will appear here":"Bài giải sẽ được xuất hiện tại đây",
        "Slove":"Giải",
        "Line quadratic equations":"Hệ PT Tuyến tính",
        "Based on the solution formula: delta = b * b - 4 * a * c, we can calculate Delta":"Dựa vào công thức nghiệm : delta = b * b - 4 * a * c, ta tính được Delta"

        
       };
     
 
 
   const memoizedGetMessage = (message: string): string => {
     return  isVietNam === "vie" ? vietnameseMessages[message] || message : englishMessages[message] || message;
   };
   const onChangeLang = (e: any) => {
     setVietNam(e.target.value);
   };
   
   return {
     memoizedGetMessage,
     onChangeLang,
     isVietNam,
   };
 };
 
 export default useLanguage;
